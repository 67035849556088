import React from "react";
import theme from "theme";
import { Theme, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"gizlilik-politikasi"} />
		<Helmet>
			<title>
				Higland Golf Kulübü
			</title>
			<meta name={"description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:title"} content={"Higland Golf Kulübü"} />
			<meta property={"og:description"} content={"Olağanüstü bir golf deneyimi için ilk destinasyonunuz olan Highland Golf Kulübü'nü keşfedin. Güzel sahamızı, birinci sınıf tesislerimizi keşfedin ve golf tutkunlarından oluşan topluluğumuza katılın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab354615ac54001870d61d/images/2-1.jpg?v=2024-08-01T08:01:46.046Z"} />
		</Helmet>
		<Components.Headernew />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			{"    "}{"    "}
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				{"        "}Gizlilik Politikası{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				{"        "}HIGHLAND GOLF KULÜBÜ olarak, kişisel verilerinizin gizliliğine büyük önem veriyoruz. Bu gizlilik politikası, kişisel bilgilerinizin nasıl toplandığını, kullanıldığını ve korunduğunu açıklamaktadır.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Toplanan Bilgiler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}HIGHLAND GOLF KULÜBÜ'ne üye olduğunuzda veya hizmetlerimizden faydalandığınızda, adınız, e-posta adresiniz, telefon numaranız gibi kişisel bilgilerinizi toplayabiliriz. Ayrıca, web sitemizi ziyaret ettiğinizde IP adresiniz, tarayıcı türünüz ve erişim saatleriniz gibi teknik bilgileri de otomatik olarak toplarız.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Bilgilerin Kullanımı{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Toplanan kişisel bilgiler, kulübümüzün hizmetlerini sunmak, üyelik işlemlerinizi yönetmek, etkinlikler ve teklifler hakkında sizi bilgilendirmek amacıyla kullanılır. Web sitemizi geliştirmek ve kullanıcı deneyimini iyileştirmek için de teknik bilgilerden faydalanırız.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Bilgilerin Korunması{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Kişisel bilgilerinizin güvenliğini sağlamak için uygun güvenlik önlemleri almaktayız. Bilgilerinizin yetkisiz erişim, kullanım veya ifşa edilmelere karşı korunması için fiziksel, elektronik ve idari prosedürler uygulanmaktadır.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Bilgi Paylaşımı{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Kişisel bilgileriniz, sizin izniniz olmadan üçüncü şahıslarla paylaşılmaz. Ancak, yasal yükümlülükler doğrultusunda veya hizmet sağlayıcılarımızla işbirliği yapmamız gerektiğinde bilgileriniz paylaşılabilir. Bu durumlarda, üçüncü tarafların bilgilerinizi güvenli bir şekilde kullanmasını sağlamak için gerekli önlemler alınır.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Çerezler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Web sitemizi kullanımınızı izlemek ve deneyiminizi kişiselleştirmek amacıyla çerezler kullanmaktayız. Çerezler, tarayıcınıza kaydedilen küçük metin dosyalarıdır ve sitemizdeki tercihlerinizi hatırlamamıza olanak tanır. Çerezleri tarayıcı ayarlarınızdan yönetebilir veya devre dışı bırakabilirsiniz, ancak bu, web sitemizin bazı özelliklerinin çalışmasını etkileyebilir.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Değişiklikler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Bu gizlilik politikası zaman zaman güncellenebilir. Politika değişiklikleri, bu sayfada güncellendiğinde yürürlüğe girer. Politika güncellemelerinden haberdar olmak için bu sayfayı düzenli olarak ziyaret etmenizi öneririz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}İletişim{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Gizlilik politikamız hakkında herhangi bir sorunuz veya endişeniz varsa, bizimle iletişime geçebilirsiniz:{" "}
				<Link href="mailto:info@highlandgolf.com">
					info@highlandgolf.com
				</Link>
				{"    "}
			</Text>
		</Section>
		<Components.Headernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});